<template>
  <div>
    <a :class="`follow_btn ${savedIndex >= 0 ? 'active' : ''}`" href="javascript:void(0);"   @click="saveData(company_id)">
      <img src="../../assets/pic/follow_btn_saved.svg" alt="" v-if="savedIndex >= 0">
      <img src="../../assets/pic/follow_btn.svg" alt="" v-else>
    </a>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {
  POST_DELETE_BRIGAD,
  POST_SAVE_BRIGAD,
} from "../../graphql/queries";
export default {
  name: "brigad_folow_btn",
  props: ["company_id", "type"],
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'user',
      'brigad',
    ]),
    savedIndex(){
      if(this.brigad){
        let savedIndex = this.brigad.findIndex(item=>item.brigad_id == this.company_id);
        return savedIndex;
      } else {
        return -1;
      }
    },
  },
  methods: {
    deleteBrigadData(savedItem){
      this.$apollo.mutate({mutation: POST_DELETE_BRIGAD,
        variables: {
          brigad_id: savedItem.id,
        }
      }).then(() =>{
        this.$store.commit("removeFromBrigad",  savedItem.brigad_id);
      });
    },
    saveData(company_id){
      if(this.savedIndex >= 0){
        this.deleteBrigadData(this.brigad[this.savedIndex])
      } else {
        this.$apollo.mutate({
          mutation:  POST_SAVE_BRIGAD,
          variables:{user_id: this.user.id, brigad_id: company_id,}
        }).then(({data}) => {
          this.$store.commit("addToBrigad",  data.createBrigadDagah);
          this.$Notice.success({
            title: this.$t("Successfully_saved"),
            desc: this.$t("Followed_brigade_menu")
          });
        })
      }
    },

  },
}
</script>

<style scoped>

</style>
